const headerId = 'js-main-header';
const activeClass = 'is-scrolled';
const header = document.getElementById(headerId);
const windowRoot = document.querySelector(':root');
const navLinks = document.querySelector('.nav-links');
const newsPostSidebar = document.querySelector('.news-post-sidebar');
let headerHeight = 0;
scrollDetect();
setHeaderHeight();

let navLinksOffset = 0;
if (navLinks) {
    navLinksOffset = navLinks.offsetTop - headerHeight;
}

window.addEventListener('scroll', () => {
    scrollpos = window.scrollY;

    setHeaderHeight();

    if (scrollpos < 100) {
        add_class_on_scroll(false);
    }
});

window.addEventListener('scroll', function () {
    if (navLinks) {
        if (window.scrollY > navLinksOffset) {
            navLinks.classList.add('-fixed');
        } else {
            navLinks.classList.remove('-fixed');
        }
    }
});

function setHeaderHeight() {
    if (!header) return;

    const headerRect = header.getBoundingClientRect();
    const { height } = headerRect;
    windowRoot.style.setProperty('--header-height', `${height}px`);

    headerHeight = height;
}

function add_class_on_scroll(show) {
    if (header) {
        if (show) {
            header.classList.add(activeClass);
        } else {
            header.classList.remove(activeClass);
        }
    }
}

function scrollDetect() {
    var lastScroll = 0;
    window.onscroll = function () {
        let currentScroll =
            document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
        if (currentScroll > 0 && lastScroll <= currentScroll) {
            lastScroll = currentScroll;
            add_class_on_scroll(true);
        } else {
            lastScroll = currentScroll;
            //add_class_on_scroll(false);
        }
    };
}
